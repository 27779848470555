<template>
  <div class="product-main-page main-page">
    <div class="product-page-header_wrap">
      <h1 class="title">Sản phẩm</h1>
      <h2 class="subtitle">Chào mừng bạn đến trang sản phẩm của Dosiin</h2>
    </div>

    <nav class="sticky-nav-bar">
      <div class="category-nav-bar" id="many-categories-navigation-bar">
        <div class="dosiin_nav-bar-swiper-container nav-bar-swiper-container">
          <Swiper
            class="swiper-wrapper"
            :freeMode="true"
            slidesPerView="auto"
            :freeModeMomentum="true"
            :threshold="5"
            :slidesPerGroup="4"
            @swiper="setSwiperRef"
          >
            <SwiperSlide
              class="swiper-slide"
              v-for="(category, index) in menuCategoroies"
              :key="index"
            >
              <router-link
                :to="
                  route.query.type_category
                    ? _categoryUrl(category) +
                      `?type_category=` +
                      route.query.type_category
                    : _categoryUrl(category)
                "
                :title="`${category.category}`"
              >
                {{ category.category }}
              </router-link>
            </SwiperSlide>
          </Swiper>
        </div>
        <div class="category-nav-bar-group-btn">
          <div class="category-nav-bar-overlay"></div>
          <div class="category-nav-bar-divider"></div>
          <div class="category-nav-bar-arrow" @click="nextPageMenuCategory">
            <i class="dsi dsi-s12 dsi-chevron-right"></i>
          </div>
          <div @click="state.showModalCategories = true">
            <i class="dsi dsi-menu-sort"></i>
          </div>
        </div>
      </div>
    </nav>

    <MainSlider
      :banners="banners.slice(0,5)"
      :options="options"
      :height="188"
      sectionClass="product-main-page-section main-slider-home"
      wrapperClass="product-main-page-slider dosiin_product-main-page"
      swiperItemImageClass="item-images-slider"
      :templateBlock="false"
    />

    <Intersection @onIntersection="onIntersectionProductTrending()">
      <ProductsSliderVertical
        wrapperClass="page-section"
        title="Sản phẩm nổi bật"
        :showLink="true"
        :link="
          queryPage.type_category
            ? `/trending?type_category=${queryPage.type_category}`
            : '/trending'
        "
        :products="products.productsTrending"
      />
    </Intersection>

    <Intersection @onIntersection="onIntersectionProductBestSeller()">
      <ProductBestSellerSkeletons v-if="!state.showProductBestSeller" />
      <section v-else class="page-section">
        <div class="section-title-wrapper">
          <h2 class="section-title">Sản phẩm bán chạy</h2>
        </div>
        <div class="dosiin_product-best-selling swiper-filter-container">
          <ul class="bestsellers-list">
            <template
              v-for="(product, index) in products.productsBestSelling"
              :key="index"
            >
              <li class="list-item bestsellers-list-item" v-if="index < 5">
                <ProductHorizon :product="product" :rating="index + 1" />
              </li>
            </template>
          </ul>
        </div>
        <div class="seemore-btn">
          <router-link
            :to="
              queryPage.type_category
                ? `/best-selling?type_category=${queryPage.type_category}`
                : '/best-selling'
            "
            class="primary-button button"
          >
            <span class="primary-link_text">Xem thêm</span>
            <i class="dsi dsi-act-arrow-forward"></i>
          </router-link>
        </div>
      </section>
    </Intersection>

    <ProductsSliderFavorite
      wrapperClass="page-section overflow"
      title="Sản phẩm mới"
      :showLink="true"
      :link="
        queryPage.type_category
          ? `/new-arrival?type_category=${queryPage.type_category}`
          : '/new-arrival'
      "
      :products="products.productsNewArrival"
    />

    <section class="page-section">
      <div class="section-title-wrapper">
        <h2 class="section-title section-title-product">Sản phẩm</h2>
        <div class="product-see-all">
          <router-link
            :to="
              queryPage.type_category
                ? `/products?type_category=${queryPage.type_category}`
                : '/products'
            "
            class="primary-button"
          >
            <span class="primary-link_text">Xem tất cả</span>
            <i class="dsi dsi-act-arrow-forward"></i>
          </router-link>
        </div>
      </div>
      <ProductLink
        gridClass="grid-50"
        :link="
          queryPage.type_category
            ? `/products?type_category=${queryPage.type_category}`
            : '/products'
        "
        :changeGrid="false"
        :query="{
          sort_by: 'on_sale',
          vendor_only: 'Y',
        }"
      />
    </section>

    <Teleport to="#modals">
      <div
        class="all-categories-modal-overlay modal"
        :class="{ open: state.showModalCategories }"
      >
        <div class="background"></div>
        <div class="all-categories-modal-container">
          <div class="all-categories-modal-header_bar sticky-title">
            <div class="all-categories-modal-all_text">Toàn bộ danh mục</div>
            <div
              class="all-categories-modal-close_icon"
              @click="state.showModalCategories = false"
            >
              <i class="dsi dsi-s16 dsi-close"></i>
            </div>
          </div>

          <ul class="all-categories_content">
            <li
              class="dosiin_all-categories all-categories"
              v-for="(category, index) in menuCategoroies"
              :key="index"
            >
              <router-link
                class="all-categories_icon"
                :to="_categoryUrl(category)"
                :title="`${category.category}`"
                @click="state.showModalCategories = false"
              >
                <i :class="['dsi dsi-s48', getCategoryIcon(category.category_id)]"></i>
              </router-link>

              <router-link
                class="all-categories_name"
                :to="_categoryUrl(category)"
                :title="`${category.category}`"
                @click="state.showModalCategories = false"
              >
                {{ category.category }}
              </router-link>
              <div class="all-categories_numbers">
                {{ category.product_count }} sản phẩm
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import MainSlider from "@/components/sliders/MainSlider";
import CategoryService from "@/services/category";
import ProductsSliderVertical from "@/components/sliders/ProductsVertical";
import ProductsSliderFavorite from "@/components/sliders/ProductsHorizon";
import ProductBestSellerSkeletons from "@/components/skeletons/sections/ProductBeseller";
import { serialize } from "@/utlis/serialize";

import Modal from "@/components/modals/Modal";
import ProductHorizon from "@/components/product/ProductItemHorizon";
import ProductLink from "@/components/product/ProductLink";

import { inject, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "shops",
  components: {
    Modal,
    ProductLink,
    MainSlider,
    ProductHorizon,
    ProductsSliderFavorite,
    ProductsSliderVertical,
    ProductBestSellerSkeletons,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const menuCategoroies = ref([]);
    const DataService = inject("DataService");
    const queryPage = inject("queryPage");
    const products = inject("products");
    const state = inject("state");

    const banners = inject("banners");
    const querySuggestion = reactive({
      sort_by: "random",
    });

    let swiperRef = null;

    const options = {
      autoplay: {
        delay: 3500,
      },
      loop: true,
      slidesPerView: 1,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => `
                        <span class="${className}"><span class="indicator-text">${addLeadingZero(
          index + 1
        )}</span><span class="separator">-</span></span>
                    `,
      },
    };

    store.dispatch("modal/initModal");

    allCategoryLevel();

    async function allCategoryLevel() {
      let query_type = route.query;
      let query_params = "";

      if (Object.keys(query_type).length !== 0) {
        query_params = "&type_category=" + query_type.type_category;
      }

      const response = await CategoryService.fetchCategories("?menu=true" + query_params);
      if (response.data) {
        menuCategoroies.value = response.data;
      }
    }

    async function onIntersectionProductTrending() {
      queryPage.value.type = "trending";
      queryPage.value.distinct_company = true;
      const response = await DataService.fetchData(serialize(queryPage.value));
      if (response.data) {
        products.productsTrending = Object.values(response.data.products);
      }
    }

    async function onIntersectionProductBestSeller() {
      queryPage.value.type = "best_seller";
      queryPage.value.distinct_company = true;
      const response = await DataService.fetchData(serialize({ ...queryPage.value }));
      if (response.data) {
        products.productsBestSelling = Object.values(response.data.products);
        state.showProductBestSeller = true;
      }
    }

    // swiper refs
    const nextPageMenuCategory = () => {
      swiperRef.slideNext();
    };

    const setSwiperRef = (swiper) => {
      swiperRef = swiper;
    };

    watch(
      () => state.showModalCategories,
      () => {
        if (state.showModalCategories) {
          store.dispatch("modal/increase");
          var stickyElement = document.querySelector("#dosiin_header-sticky");
          stickyElement.classList.add("mode-sticky");
        } else {
          store.dispatch("modal/decrease");
          var stickyElement = document.querySelector("#dosiin_header-sticky");
          if (window.pageYOffset === 0) stickyElement.classList.remove("mode-sticky");
        }
      }
    );

    return {
      state,
      banners,
      menuCategoroies,
      options,
      products,
      querySuggestion,
      queryPage,
      //methods
      setSwiperRef,
      nextPageMenuCategory,
      onIntersectionProductTrending,
      onIntersectionProductBestSeller,
      route,
    };
  },
};
</script>
<style>
.all-categories_content .all-categories .all-categories_name {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 84px;
}
</style>
