<template>
  <ShopsMB :key="key" v-if="layout == 4" />
  <ShopsPC :key="key" v-else />
</template>

<script>
import ShopsMB from "@/pages/mb/Shops";
import ShopsPC from "@/pages/pc/Shops";
import { ref, provide, watch, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { serialize } from "@/utlis/serialize";
import BlockService from "@/services/block";
import DataService from "@/services/dosiin";
import ProductService from "@/services/product";
import BrandServices from "@/services/brand";

export default {
  name: "shops",
  components: {
    ShopsMB,
    ShopsPC,
  },
  setup() {
    const banners = ref([]);
    const route = useRoute();
    const key = ref("");
    const query = ref({
      type_category: route.query.type_category,
    });

    const state = reactive({
      showModalCategories: false,
      showProductBestSeller: false,
    });

    const products = reactive({
      productsTrending: [],
      productsBestSelling: [],
      productsNewArrival: [],
    });

    getBanners();

    async function getBanners() {
      // const response = await BlockService.fetchBlocks(id);

      // if (response.data) {
      //   banners.value = response.data;
      // }
      let type_category = "";
      if (route.query.type_category) {
        type_category = route.query.type_category;
      }

      const response = await BrandServices.fetchBrandsBanner({ type: type_category, percent: 40 });

      if (response && response.data) {
        banners.value = response.data.companies;
      }
    }

    watch(
      () => route.query,
      () => {
        if (route.query.type_category) {
          query.value.type_category = route.query.type_category;
        } else {
          query.value.type_category = "";
        }

        getBanners();
        getProductNewArrival();
        key.value = serialize(query.value);
      }
    );

    async function getProductNewArrival() {
      const response = await ProductService.fetchProducts({
        load_new_arrival: "Y",
        vendor_only: "Y",
        type_category: route.query.type_category,
      });
      if (response.data) {
        products.productsNewArrival = response.data.products;
      }
    }

    onMounted(() => {
      getProductNewArrival();
    });

    provide("queryPage", query);
    provide("banners", banners);
    provide("DataService", DataService);
    provide("products", products);
    provide("state", state);
    // provide("getProductNewArrival", getProductNewArrival);
    return {
      key,
    };
  },
};
</script>
