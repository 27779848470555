<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <MainSlider
      :banners="banners.slice(0,5)"
      :options="options"
      :height="425"
      sectionClass="page-section dosiin_pl-0 dosiin_pr-0"
      wrapperClass="main-page-slider overflow"
      swiperClass="swiper-wrapper dosiin_swiper-effect-coverflow"
      :templateBlock="false"
    />

    <Intersection @onIntersection="onIntersectionProductTrending()">
      <ProductsSliderHorizon
        wrapperClass="page-section"
        :title="$t('products.products_trending')"
        gridClass="grid-20"
        :showSlideLink="true"
        :link="
          queryPage.type_category
            ? `/trending?type_category=${queryPage.type_category}`
            : '/trending'
        "
        :products="products.productsTrending"
        :options="{
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 9,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }"
      />
    </Intersection>

    <Intersection @onIntersection="onIntersectionProductBestSeller()">
      <ProductBestSellerSkeletons
        v-if="!state.showProductBestSeller"
        :number="9"
        gridClass="grid-33"
      />
      <section v-else class="page-section">
        <div class="section-title-wrapper">
          <h2 class="section-title">Sản phẩm bán chạy</h2>
          <div class="product-see-all">
            <router-link
              :to="
                queryPage.type_category
                  ? `/best-selling?type_category=${queryPage.type_category}`
                  : '/best-selling'
              "
              class="primary-button"
            >
              <span class="primary-link_text">Xem tất cả</span>
              <i class="dsi dsi-act-arrow-forward"></i>
            </router-link>
          </div>
        </div>
        <div>
          <ul class="bestsellers-list grid grid-33">
            <template
              v-for="(product, index) in products.productsBestSelling"
              :key="index"
            >
              <li class="grid-item list-item bestsellers-list-item" v-if="index < 9">
                <ProductHorizon :product="product" :rating="index + 1" />
              </li>
            </template>
          </ul>
        </div>
      </section>
    </Intersection>

    <ProductsSliderHorizon
      wrapperClass="page-section"
      title="Sản phẩm mới"
      gridClass="grid-20"
      :showSlideLink="true"
      :link="
        queryPage.type_category
          ? `/new-arrival?type_category=${queryPage.type_category}`
          : '/new-arrival'
      "
      :products="products.productsNewArrival"
      :options="{
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 9,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }"
    />

    <section class="page-section">
      <div class="section-title-wrapper">
        <h2 class="section-title section-title-product">Sản phẩm</h2>
        <div class="product-see-all">
          <router-link
            title="Xem tất cả sản phẩm"
            :to="
              queryPage.type_category
                ? `/products?type_category=${queryPage.type_category}`
                : '/products'
            "
            class="primary-button"
          >
            <span class="primary-link_text">Xem tất cả</span>
            <i class="dsi dsi-act-arrow-forward"></i>
          </router-link>
        </div>
      </div>

      <ProductLink
        :link="
          queryPage.type_category
            ? `/products?type_category=${queryPage.type_category}`
            : '/products'
        "
        :changeGrid="false"
        :query="{
          sort_by: 'on_sale',
          vendor_only: 'Y',
        }"
      />
    </section>
  </div>
</template>

<script>
import MainSlider from "@/components/sliders/MainSlider";
import ProductBestSellerSkeletons from "@/components/skeletons/sections/ProductBeseller";
import ProductTrending from "@/components/skeletons/sections/ProductTrending";
import ProductItem from "@/components/product/ProductItem";
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
import ProductHorizon from "@/components/product/ProductItemHorizon";
import ProductLink from "@/components/product/ProductLink";
import { serialize } from "@/utlis/serialize";

import { inject } from "vue";
export default {
  components: {
    MainSlider,
    ProductItem,
    ProductLink,
    ProductTrending,
    ProductHorizon,
    ProductsSliderHorizon,
    ProductBestSellerSkeletons,
  },
  setup() {
    const banners = inject("banners");
    const DataService = inject("DataService");
    const queryPage = inject("queryPage");
    const products = inject("products");
    const state = inject("state");
    const options = {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 0,
        stretch: 370,
        depth: 250,
        modifier: 1.5,
        slideShadows: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => `
                        <span class="${className}"><span class="indicator-text">${addLeadingZero(
          index + 1
        )}</span><span class="separator">-</span></span>
                    `,
      },
      breakpoints: {
        1600: {
          coverflowEffect: {
            rotate: 0,
            stretch: 850,
            depth: 0,
            modifier: 1,
            scale: 0.8,
            slideShadows: true,
          },
        },
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    async function onIntersectionProductTrending() {
      queryPage.value.type = "trending";
      queryPage.value.distinct_company = true;
      const response = await DataService.fetchData(serialize(queryPage.value));

      if (response.data) {
        products.productsTrending = Object.values(response.data.products);
      }
    }

    async function onIntersectionProductBestSeller() {
      queryPage.value.type = "best_seller";
      queryPage.value.distinct_company = true;
      const response = await DataService.fetchData(serialize({ ...queryPage.value }));
      if (response.data) {
        products.productsBestSelling = Object.values(response.data.products);
        state.showProductBestSeller = true;
      }
    }

    return {
      state,
      banners,
      options,
      products,
      queryPage,
      // methods
      onIntersectionProductTrending,
      onIntersectionProductBestSeller,
    };
  },
};
</script>

<style>
.dosiin_pl-0 {
  padding-left: 0;
}

.dosiin_pr-0 {
  padding-right: 0;
}
</style>
